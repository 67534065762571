





















import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RunConcentration } from 'common-modules/src/store/interface/RunConcentration';
import Concentrations from 'common-modules/src/store/Concentrations';
import LmsGradebookCourse from '@/components/gradebook/LmsGradebookCourse.vue';

@Component({
  components: {
    LmsGradebookCourse,
  },
  computed: mapGetters([
    'currentUser',
    'baseConcentrations',
  ]),
})
export default class JwlGradebook extends Vue {
  baseConcentrations!: Concentrations;

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  get concentration (): RunConcentration | undefined {
    const routeConcentrationCode = this.$route.params.concentration;
    return this.baseConcentrations.dataAsFlatArray().find((concentration) => concentration.code === routeConcentrationCode);
  }

  get rootClass (): Record<string, boolean> {
    return {
      'jwl-gradebook--bachelor': this.concentration?.programmeType === 'bachelor',
      'jwl-gradebook--professional': this.concentration?.programmeType === 'professional',
    };
  }
}
