


























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RunCourse } from 'common-modules/src/store/interface/RunCourse';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import JwlToggle from '@/components/jwlToggle.vue';
import {
  GradebookClassroom as GradebookClassroomInterface,
  GradebookCourse as GradebookCourseInterface,
  GradingSchemaStep,
} from '@/store/interface/Gradebook';
import { Announcement } from '@/store/Announcement';
import GradebookClassroom from '@/components/gradebook/GradebookClassroom.vue';

const JwlError = () => import('common-modules/src/components/CommonError.vue');
const JwlProfileImage = () => import('common-modules/src/components/CommonProfileImage.vue');
const JwlAnnouncementBox = () => import('@/components/jwlAnnouncementBox.vue');
const JwlAnnouncementEdit = () => import('@/components/jwlAnnouncementEdit.vue');
const JwlButton = () => import('@/components/JwlButton.vue');
const JwlFacilitatorGradebook = () => import('@/components/jwlFacilitatorGradebook.vue');
const JwlGradebookExport = () => import('@/components/jwlGradebookExport.vue');
const JwlStudentGradebook = () => import('@/components/jwlStudentGradebook.vue');

export interface Classrooms {
  local: GradebookClassroomInterface[];
  global: GradebookClassroomInterface[];
  localResponsible: GradebookClassroomInterface[];
  globalResponsible: GradebookClassroomInterface[];
  hasLocal: boolean;
  hasGlobal: boolean;
  hasLocalResponsible: boolean;
  hasGlobalResponsible: boolean;
  hasMany: boolean;
  hasManyResponsible: boolean;
}

@Component({
  components: {
    GradebookClassroom,
    JwlAnnouncementBox,
    JwlAnnouncementEdit,
    JwlButton,
    JwlError,
    JwlFacilitatorGradebook,
    JwlGradebookExport,
    CommonIcon,
    JwlProfileImage,
    JwlStudentGradebook,
    JwlToggle,
  },
})
export default class LmsGradebookCourse extends Vue {
  @Prop(Object)
  gradebookCourse!: RunCourse;

  course: GradebookCourseInterface|null = null;
  open = false;
  loadingState = 0;
  error = null;
  editAnnouncement: Announcement|null = null;
  gradingSchema: GradingSchemaStep[]|null = null;
  activeClassroom: GradebookClassroomInterface|null = null;
  openGradebook = false;

  createNewAnnouncement (announcement: Announcement|null): void {
    if (announcement) {
      this.editAnnouncement = announcement;
    } else {
      this.editAnnouncement = {
        id: 'new',
        subject: '',
        text: '',
        createdAt: new Date().toString(),
        createdBy: null,
        expiresAt: null,
        courseCode: '',
        classroomId: null,
        programmeType: this.gradebookCourse.programmeType,
        isGlobal: false,
      };
    }
  }

  updateAnnouncement (announcement: Announcement): void {
    const { course } = this;
    if (this.editAnnouncement && course) {
      if (this.editAnnouncement.id === 'new') {
        course.announcements.push(announcement);
      } else {
        const originalIndex = course.announcements.findIndex((courseAnnouncement) => courseAnnouncement.id === announcement.id);
        if (originalIndex !== -1) {
          course.announcements[originalIndex] = announcement;
        }
      }

      setTimeout(() => {
        this.editAnnouncement = null;
      }, 2500);
    }
  }

  cancelAnnouncement (): void {
    this.editAnnouncement = null;
  }

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  toggleOpen (): void {
    this.open = !this.open;
    if (this.open && this.loadingState === 0) {
      this.loadData();
    }
  }

  loadData (): void {
    if (this.loadingState < 1) {
      this.loadingState = 1;
      this.$store.dispatch('getData', `gradebook/${this.gradebookCourse.code}`)
        .then((data) => {
          this.course = data.course;
          this.setActiveClassroom();
          if (data.gradingSchema) {
            this.gradingSchema = data.gradingSchema.steps.sort((stepA: GradingSchemaStep, stepB: GradingSchemaStep) => stepB.lowerLimitRange - stepA.lowerLimitRange);
          }
          this.loadingState = 2;
        })
        .catch((e) => {
          this.error = e;
          this.loadingState = -1;
        });
    }
  }

  setActiveClassroom (classroom: GradebookClassroomInterface|null = null): void {
    if (classroom) {
      this.activeClassroom = classroom;
    } else if (this.course?.role === 'onsite_facilitator') {
      [this.activeClassroom] = this.classrooms.local;
    } else {
      [this.activeClassroom] = this.classrooms.global;
    }
  }

  get classrooms (): Classrooms {
    const { course } = this;
    const global = this.course?.classrooms.filter((classroom) => classroom.type === 'global') || [];
    const local = this.course?.classrooms.filter((classroom) => classroom.type === 'local') || [];
    let localResponsible: GradebookClassroomInterface[] = [];
    let globalResponsible: GradebookClassroomInterface[] = [];

    if (course) {
      if (course.role === 'admin' || course.role === 'student') {
        localResponsible = local;
        globalResponsible = global;
      } else if (course.role === 'onsite_facilitator') {
        localResponsible = local;
      } else if (course.role === 'online_facilitator') {
        globalResponsible = global;
      }
    }

    return {
      local: local.sort(),
      global: global.sort(),
      localResponsible: localResponsible.sort(),
      globalResponsible: globalResponsible.sort(),
      hasLocal: local.length > 0,
      hasGlobal: global.length > 0,
      hasLocalResponsible: localResponsible.length > 1 || (localResponsible.length === 1 && globalResponsible.length > 1),
      hasGlobalResponsible: globalResponsible.length > 1 || (globalResponsible.length === 1 && localResponsible.length > 1),
      hasMany: local.length > 1 || global.length > 1,
      hasManyResponsible: localResponsible.length > 1 || globalResponsible.length > 1,
    };
  }

  get filteredAnnouncements (): Announcement[] {
    const announcementClasses: Announcement[] = [];
    const courseAnnouncements = this.course?.announcements;
    if (courseAnnouncements) {
      courseAnnouncements.forEach((announcement) => {
        if (announcement.classroomId === this.activeClassroom?.id) {
          announcementClasses.push(announcement);
        }
      });
    }
    return announcementClasses;
  }

  get showGlobalClassroomSelection (): boolean {
    return this.classrooms.hasGlobalResponsible && this.classrooms.hasManyResponsible;
  }

  get showLocalClassroomSelection (): boolean {
    return this.classrooms.hasLocalResponsible && this.classrooms.hasManyResponsible;
  }

  created (): void {
    const { focusCourse } = this.$route.query;

    if (focusCourse === this.gradebookCourse.code) {
      this.toggleOpen();
      this.openGradebook = true;
    }
  }
}
