































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RunCourse } from 'common-modules/src/store/interface/RunCourse';
import {
  GradebookUser,
  GradebookClassroom as GradebookClassroomInterface,
  GradebookCourse,
} from '@/store/interface/Gradebook';
import { Classrooms as ClassroomsInterface } from '@/components/gradebook/LmsGradebookCourse.vue';

const JwlButton = () => import('@/components/JwlButton.vue');
const JwlContactInitiator = () => import('@/components/lms-contact/LmsContactInitiator.vue');
const JwlProfileImage = () => import('common-modules/src/components/CommonProfileImage.vue');
const JwlToggle = () => import('@/components/jwlToggle.vue');

interface StudentFilterInterface {
  id: number;
  name: string|null;
  type: 'global'|'local'|string;
}

@Component({
  components: {
    JwlButton,
    JwlContactInitiator,
    JwlProfileImage,
    JwlToggle,
  },
})
export default class GradebookClassroom extends Vue {
  @Prop(Object)
  activeClassroom!: GradebookClassroomInterface;

  @Prop(Object)
  classrooms!: ClassroomsInterface;

  @Prop(Object)
  runCourse!: RunCourse;

  @Prop(Object)
  gradebookCourse!: GradebookCourse;

  studentFilterClassroom: number|null = null;
  openOnline = false;
  openOnsite = false;
  openStudents = false;

  setStudentFilterClassroom (classroom: number|null): void {
    this.studentFilterClassroom = classroom;
  }

  get filteredOnlineFacilitators (): GradebookUser[] {
    const { activeClassroom } = this;

    if (activeClassroom.type === 'global') {
      return this.activeClassroom?.facilitators || [];
    }

    const addedFacilitators: number[] = [];
    const filteredFacilitators: GradebookUser[] = [];
    activeClassroom.students.forEach((student) => {
      this.classrooms.global.forEach((globalClassroom) => {
        if (globalClassroom.id === student.global) {
          globalClassroom.facilitators.forEach((facilitator) => {
            if (!addedFacilitators.includes(facilitator.id)) {
              addedFacilitators.push(facilitator.id);
              filteredFacilitators.push(facilitator);
            }
          });
        }
      });
    });

    return Array.from(filteredFacilitators);
  }

  get filteredOnSiteFacilitators (): GradebookUser[] {
    const { activeClassroom } = this;

    if (activeClassroom.type === 'local') {
      return this.activeClassroom?.facilitators || [];
    }

    const addedFacilitators: number[] = [];
    const filteredFacilitators: GradebookUser[] = [];
    activeClassroom.students.forEach((student) => {
      this.classrooms.local.forEach((localClassroom) => {
        if (localClassroom.id === student.local) {
          localClassroom.facilitators.forEach((facilitator) => {
            if (!addedFacilitators.includes(facilitator.id)) {
              addedFacilitators.push(facilitator.id);
              filteredFacilitators.push(facilitator);
            }
          });
        }
      });
    });

    return filteredFacilitators;
  }

  get filteredStudents (): GradebookUser[] {
    if (this.studentFilterClassroom !== null) {
      return this.activeClassroom.students.filter((student: GradebookUser) => student.global === this.studentFilterClassroom || student.local === this.studentFilterClassroom);
    }
    return this.activeClassroom.students || [];
  }

  get studentFilterClassrooms (): StudentFilterInterface[] {
    const filters: StudentFilterInterface[] = [];
    const knownClasses: number[] = [];
    const searchForGlobal = this.classrooms.localResponsible.length > 0;

    if (['online_facilitator', 'onsite_facilitator'].includes(this.gradebookCourse.role)) {
      this.activeClassroom.students.forEach((student) => {
        if (searchForGlobal && student.global && !knownClasses.includes(student.global)) {
          knownClasses.push(student.global);
          filters.push({
            id: student.global,
            name: student.globalClassroomName,
            type: 'local',
          });
        } else if (!searchForGlobal && student.local && !knownClasses.includes(student.local)) {
          knownClasses.push(student.local);
          filters.push({
            id: student.local,
            name: student.localClassroomName,
            type: 'local',
          });
        }
      });
    }

    return filters;
  }
}
